<template>
  <div class="container404">
    <img src="@/assets/icon/404.png" />
    <p><el-button type="primary" @click="goHome">回到首页</el-button></p>
  </div>
</template>

<script>

export default {
  name: 'Page404',
  computed: {
    message() {
      return 'The webmaster said that you can not enter this page...'
    }
  },
  methods:{
    goHome(){
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
  .container404{
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    margin-left: -129px;
    text-align: center;
  }
</style>
